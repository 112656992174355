// samordnaopptak grade-table-calculator.less

#main .grade-table-calculator {
  border: 1px solid @color-neutral--light;
  box-shadow: 4px 4px 0 0 @color-neutral--light;
  padding: 30px;
  margin: 30px 0;
  line-height: 1.5em;
  .heading {
    font-size: 2rem;
  }
  label,
  [role="status"] {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  label {
    width: fit-content;
    margin: 10px 0;
    p::after {
      content: ":";
    }
  }
  input,
  button {
    margin: 0;
  }
  input {
    background-color: @color-neutral--background;
    border-bottom-color: @color-neutral--dark;
    width: 5em;
  }
  button {
    font-size: inherit;
  }
  p {
    margin: 0;
  }
  [role="status"] {
    margin-bottom: 20px;
    span {
      background-color: @color-secondary;
      color: white;
      padding: 0 5px;
    }
  }
  // Class added with JS when user has interacted, to prevent height variation when empty status
  &.user-interacted {
    [role="status"] {
      min-height: 1.5em; // Same as line-height
    }
  }
}
// Control the table. Class added with js
.calculated-table {
  &:not(.expanded) {
    display: none;
  }
  th, td {
    &.match {
      outline: 2px solid @color-secondary;
      outline-offset: -2px;
      // Style second cell. Importants in case of inline styles.
      & + .match {
        background-color: @color-secondary !important;
        color: white !important;
      }
    }
  }
}


/* CSS for samordnaopptak */
@import "../../../../../../vrtx/groups/design-uio1/src/resources/css/util.less";
@import "../../../../../../lib/uio1/profile/css/util.less";

/* Font-face CSS that also works on Android 2.3 */
@font-face {
  font-family: "DIN-OT-bold";
  src: url("/vrtx/dist/resources/fonts/dinweb-bold.eot?#iefix")
      format("embedded-opentype"),
    url("/vrtx/dist/resources/fonts/dinweb-bold.woff") format("woff"),
    url("/vrtx/dist/resources/fonts/dinweb-bold.ttf") format("truetype");
}

#hidnav a[href="#bottomnav"] {
  display: none;
}

a {
  color: @color-link;
  font-weight: 600;
}
.vrtx-frontpage-box.vrtx-more-false>h2 a {
  color: @color-link;
}

// global focus style for wcag compliance
:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 4px @color-link;
}

.lastModified, .published-date, .vrtx-authors, .vrtx-byline, .vrtx-comment .comment-date, .vrtx-date-info, .vrtx-event-component-misc, .vrtx-featured-item .vrtx-date, .vrtx-image-creation-time, .vrtx-message-line-publish-date, .vrtx-resource .duration, div.time-and-place {
  color: #555;
}

h1,
#vrtx-searchview h1,
#vrtx-personsearch h1,
#vrtx-unitsearch h1,
#error h1 {
  font-family: "DIN-OT-bold";
  font-weight: 400;
  color: @color-dark;
  font-size: 40px;
  padding-top: 35px;
  max-width: 970px;
}

#vrtx-frontpage h1 {
  max-width: 670px;
}
.vrtx-frontpage-full-width.total-main #main h1 {
  max-width: 970px;
}

h2 {
  font-family: Arial, sans;
  color: @color-dark;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
}
.vrtx-frontpage-box h2 {
  font-size: 30px;
}
#vrtx-additional-content .vrtx-frontpage-box {
  h2 {
    font-size: 24px !important;
  }
  div.vrtx-box-content h2 {
    font-size: 20px;
    line-height: 28px;
  }
}
#main
  #vrtx-additional-content
  .vrtx-frontpage-box:not(.vrtx-search-main-links-box):first-child {
  margin-top: 0px !important;
}

h3 {
  font-family: arial;
  color: @color-dark;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}
.vrtx-frontpage-box .vrtx-box-content h3,
#vrtx-additional-content .vrtx-frontpage-box h3 {
  font-size: 20px;
  line-height: 28px;
}
h4 {
  font-family: arial;
  color: @color-dark;
  font-weight: 700;
}

.not-for-ansatte {
  .header-search-expand {
    background: @color-light url("../images/magnifyingglass.svg") no-repeat 9px
      9px !important;
    background-size: 20px auto !important;
    &:focus,
    &:hover {
      background: @color-light url("../images/magnifyingglass-hover.svg")
        no-repeat 9px 9px !important;
      background-size: 20px auto !important;
    }
  }
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white):not(.searchsubmit):not(.submit-button-link):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-contrast !important;
    color: @color-dark !important;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      color: @color-light;
      background-color: @color-neutral--light !important;
    }
  }
  .header-search {
    top: 55px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }

  .mainButtonsBg(@color-button--light, @color-button--dark);
  .mainButtonsFontColor(@color-light, @color-light);

  #main .button-large {
    border: 3px solid @color-neutral--light;
    border-radius: 2px;
    height: 60px;
    width: fit-content;
    padding: 14px 20px;

    background: @color-button--dark; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(
      @color-button--light,
      @color-button--dark
    ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
      @color-button--light,
      @color-button--dark
    ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
      @color-button--light,
      @color-button--dark
    ); /* For Firefox 3.6 to 15 */
    background: linear-gradient(
      @color-button--light,
      @color-button--dark
    ); /* Standard syntax */
    &:hover,
    &:focus {
      // background: @color-light;
      // color: @color-link;
      background: @color-button-hover--dark !important; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(
        @color-button-hover--light,
        @color-button-hover--dark
      ) !important; /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(
        @color-button-hover--light,
        @color-button-hover--dark
      ) !important; /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(
        @color-button-hover--light,
        @color-button-hover--dark
      ) !important; /* For Firefox 3.6 to 15 */
      background: linear-gradient(
        @color-button-hover--light,
        @color-button-hover--dark
      ) !important; /* Standard syntax */
      text-decoration: underline;
      color: @color-light;
    }
    span {
      background: url(/vrtx/dist/resources/images/arrow-right-white.svg) 100% 55% no-repeat;
      width: 100%;
      display: inline-block;
      background-size: 25px 25px;
      padding-right: 40px;
    }
  }

  #head-wrapper {
    background: url(../images/menu-bg.png) repeat-x bottom left @color-light !important;
    border-bottom: 1px solid #d0d0d0;
    #head {
      .uio-app-name {
        display: none;
      }
      height: 170px;
      .head-menu {
        right: 95px;
        top: 55px;
        >ul {
          display: none;
        }
        .vrtx-dropdown-link {
          background: transparent
            url("/vrtx/dist/resources/images/language-dropdown-toggle-black.png")
            no-repeat scroll 100% 53%;
          margin-right: 7px;
        }
        a {
          color: @color-neutral--dark;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        .home-link {
          width: 172px;
          height: 50%;
          top: 40px;
          left: 20px;
          position: absolute;
          max-width: 172px;
          &:hover,
          &:focus {
            color: @color-link !important;
            text-decoration: none;
            border: 3px solid @color-link;
            margin: -3px 0px 0px -3px;
            border-radius: 5px;
            width: 178px;
            max-width: 178px;
            box-shadow: none; // override global rule
          }
        }
        span.context {
          left: 200px;
          top: 83px;
          position: absolute;
          font-size: 24px;
          a {
            color: @color-neutral--dark;
          }
        }
        .top-menu {
          font-size: 0.778em;
          padding-right: 0;
          position: absolute;
          right: 0px;
          top: 18px;
          font-weight: 700;
          a {
            padding-left: 20px;
          }
        }
        .faq {
          position: absolute;
          top: 100px;
          right: 0px;
        }
        #logo {
          background: url(../images/logo.png) no-repeat scroll left center
            transparent;
          height: 73px;
          left: 14px;
          top: 43px;
          width: 430px;
        }
      }
    }
  }
}

.tekststorrelse {
  display: none;
  position: absolute;
  right: 0;
  width: 185%;
  z-index: 10000;
  font-size: 36px;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0.5em 1em;
}
.lighter {
  color: #ccc;
}
.larger {
  font-size: 18px;
}
.smaller {
  font-size: 12px;
}

#globalnav {
  background: transparent;
  ul {
    li.vrtx-active-item a {
      background: transparent !important;
      text-decoration: none;
      color: @color-neutral--dark !important;
      border-bottom: 4px solid @color-neutral !important;
    }
    li a {
      font-weight: 600;
      padding: 11px 0 7px 0 !important;
      margin: 0px 15px 0px 20px;
      color: @color-link !important;
      &:hover,
      &:focus {
        background: transparent !important;
        text-decoration: none;
        border-bottom: 4px solid @color-link !important;
      }
    }

    li.tidsfrister {
      display: none;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu {
  margin-top: 35px;
  li {
    background: @color-neutral--background;
    > a {
      background: @color-neutral--background;
    }
    &.vrtx-ancestor {
      background: transparent
        url(/vrtx/dist/resources/images/breadcrumb-menu-ancestor-arrow-down.png)
        no-repeat 0% 100%;
      z-index: 3;
      padding-bottom: 13px;
    }
    &.vrtx-parent {
      margin-top: -20px;
    }
    &.vrtx-ancestor > a,
    &.vrtx-parent {
      font-size: 15px;
      font-size: 1.5rem;
      line-height: 22px;
      border: 1px solid @color-neutral--light;
    }
    &.vrtx-child {
      > a,
      > a.vrtx-marked,
      > a.vrtx-after-marked {
        background-color: @color-light;
        border-top: 1px solid @color-neutral--light;
        &.vrtx-marked {
          color: @color-dark;
        }
      }
      & > a.vrtx-marked {
        border-top: 1px solid @color-neutral--light;
      }
    }
    & > a.vrtx-marked {
      background: @color-neutral--background;
      color: @color-dark;
    }
    &.vrtx-child {
      & > a::before {
        content: " ";
        background: url(/vrtx/dist/resources/images/bullet-li.png) no-repeat 0
          7px;
      }
      a {
        color: @color-link;
      }
    }
  }
}
#vrtx-frontpage #vrtx-content:not(.frontpage-wide) #vrtx-main-content:not(.vrtx-empty-additional-content), 
#vrtx-main-content:not(.vrtx-hide-additional-content-true):not(.vrtx-empty-additional-content) {
  .vrtx-introduction {
    line-height: 1.5;
  }
}
.vrtx-introduction {
  background: url(../images/zigzag.png) repeat-x scroll -3px 100% transparent;
  font-family: arial, sans-serif;
  color: #444;
  margin-bottom: 20px !important;
  padding-bottom: 15px !important;
  font-size: 22px !important;
  font-size: 2.2rem !important;
  line-height: 1.5;
}

#vrtx-frontpage-introduction {
  margin-bottom: 0;
  .vrtx-introduction {
    background: none;
  }
  .vrtx-introduction-image {
    width: auto !important;
  }
}
.vrtx-introduction-image.small-intro-image {
  width: auto !important;
}

.vrtx-frontpage-box {
  .vrtx-distach-bottom {
    margin-bottom: 20px;
  }
  &.so-box,
  &.white-box {
    &.third-box-left:not(.third-box-double),
    &.third-box-middle {
      margin-right: 20px;
      width: 30%;
    }
    &.half-box-left,
    &.half-box-right {
      width: 48%;
      height: auto !important;
    }
    &.half-box-left {
      margin-right: 20px;
    }
    border: 1px solid silver;
    box-shadow: 4px 4px @color-neutral--light;
    padding: 14px;
  }
  &.vrtx-frontpage-box-picture.image-left {
    border: 1px solid silver;
    box-shadow: 4px 4px @color-neutral--light;
    width: 100%;
    .vrtx-frontpage-box-picture {
      float: left;
      margin: 10px 20px 10px 0;
      clear: none;
    }
  }
  &.responsive-pos-97,
  &.responsive-pos-98,
  &.responsive-pos-99 {
    height: 390px !important;
    display: flex;
    flex-direction: column;
  }
  &.responsive-pos-97 {
    .center-image {
      margin-top: 40px;
      margin-left: -14px;
      margin-right: -14px;
    }
  }
  &.responsive-pos-98 {
    .center-image {
      margin-top: 12px;
      margin-left: -14px;
      margin-right: -14px;
    }
  }
  &.responsive-pos-99 {
    width: 207px;
    .center-image {
      margin-top: 58px;
      margin-left: -14px;
      margin-right: -14px;
    }
  }

  div.vrtx-more a,
  a.all-messages,
  a.more-url,
  .vrtx-frontpage-box a.more,
  #vrtx-additional-content a.more,
  #vrtx-additional-content a.all-messages {
    background: url(../images/blue-arrow-small.png) no-repeat scroll 95% 3px;
    padding: 3px 50px 1px 45px;
  }

  .vrtx-more {
    border-top: 3px solid @color-lines;
    margin-top: 30px;
    span {
      padding: 14px 0;
    }
  }
}

.grid-container {
  &.grid-color-transparent {
    background-color: @color-light !important;
    + .row-all-colored {
      margin-bottom: 40px;
      background-color: @color-light;
    }
  }
}

#main {
  
  @media (min-width: 605px) {
    .row-all-colored {
      border-left-color: @colored-box;
      border-left-width: 20px;
      box-shadow: -20px 0 0 0 @colored-box;
    }
    .vrtx-frontpage-box {
      &.grey-box, &.half-box-right {
        padding-left: 0;
      }
    }
  }
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) {
    .vrtx-frontpage-box.third-box-left.no-vertical-line {
      border-right: 0;
      padding-right: 14px;
      &.grey-box {
        padding-left: 15px;
      }
    }
    .vrtx-frontpage-box.third-box-right.no-left-border {
      border-left: none;
      &.grey-box {
        padding-left: 55px;
      }
    }
    .vrtx-frontpage-box.third-box-right.no-vertical-line {
      padding-left: 0px;
    }
  }
}
.vrtx-frontpage-full-width #main {
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) {
    .vrtx-frontpage-box.third-box-left.no-vertical-line {
      &.grey-box {
        padding-left: 30px;
      }
    }
    .vrtx-frontpage-box.third-box-right.no-left-border {
      &.grey-box {
        padding-left: 70px;
      }
    }
  }
}

.vrtx-frontpage-box.white-box.shadowbox {
  padding: 0;
}
.vrtx-frontpage-box.grey-box.no-vertical-line {
  margin-right: 14px;
  margin-left: -14px;
}

.shadowbox,
.vrtx-frontpage-box.grey-box.shadowbox {
  border: 1px solid silver;
  box-shadow: 4px 4px @color-neutral--light;
  padding: 0 15px 15px 15px;
  background-color: @color-light;
}

#main .vrtx-frontpage-box.shadowbox,
.shadowbox {
  height: fit-content !important;
  padding-left: 15px !important;
  padding-top: 15px;
  &.tidsfrist {
    padding: 0 !important;
    margin-left: 0px;
    margin-right: 0px;
    h2 {
      background: url(../images/clock.svg) no-repeat scroll 14px 23px
        transparent;
      padding: 20px 0 2px 45px !important;
    }
  }
  ul.items > li {
    background: none !important;
    border-bottom: 1px solid @color-lines;
    list-style-type: none;
    width: 100%;
    margin-left: 0px;
    padding: 0px 15px 10px 14px;
    &:before {
      content: " ";
    }
  }
  div.vrtx-more {
    border-top: 0;
    margin-top: 10px;
    padding-right: 10px;
    float: right;
    span {
      padding: 21px 0;
      a {
        background-position: 100% 0;
        padding: 0 30px 0 0;
        &:before {
          width: 20px;
          height: 20px;
          margin-right: 0px;
        }
      }
    }
  }
}
.half-box-left {
  width: 47%;
  margin-right: 15px;
}
.third-box-left:not(.third-box-double),
.third-box-middle {
  width: 31%;
  margin-right: 15px;
}

.two-column-list .vrtx-box-content {
  column-count: 2;
}
.three-column-list .vrtx-box-content {
  column-count: 3;
}

.vrtx-frontpage-full-width.total-main #main .grid-color-transparent .row {
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  margin-bottom: 40px;

  .vrtx-frontpage-box {
    &.third-box-left:not(.third-box-double),
    &.third-box-middle,
    &.third-box-right {
      width: 33%;
    }
    &.white-box,
    &.half-box-left,
    &.third-box-left:not(.third-box-double),
    &.third-box-middle,
    &.half-box-right,
    &.third-box-right {
      padding: 0;
      height: unset !important;
      &.img-box.fill-background .vrtx-box-content {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 100%;
        height: 100%;
        > * {
          flex: 1 100%;
        }
        h2 {
          padding: 14px 14px 0;
        }
        p {
          padding: 0 14px;
          &.center-image {
            margin-bottom: -8px;
            padding: 30px 0 0;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#vrtx-additional-content {
  a {
    font-weight: 600;
  }

  .vrtx-frontpage-box {
    border: 1px solid silver;
    -moz-box-shadow: 4px 4px @color-neutral--light;
    -webkit-box-shadow: 4px 4px @color-neutral--light;
    box-shadow: 4px 4px @color-neutral--light;
    padding: 14px;
    &.vrtx-distach-bottom:not(.grey-box):not(.grey-box-light):not(.uio-info-message):not(#footer-wrapper) {
      border: 1px solid silver;
    }

    &.tidsfrist {
      padding: 0 !important;
      h2 {
        background: url(../images/clock.svg) no-repeat scroll 14px 23px
          transparent;
        padding: 20px 0 2px 45px;
      }
    }
    .vrtx-distach-bottom {
      margin-bottom: 20px;
    }
    .vrtx-feed ul li {
      margin-left: 0px;
      padding: 0px 14px 10px 14px !important;
      &::before {
        content: " ";
      }
    }
  }
  .vrtx-frontpage-box:not(.vrtx-additional-content-last):not(.vrtx-distach-bottom),
  .vrtx-feed:not(.vrtx-additional-content-last):not(.vrtx-distach-bottom),
  .vrtx-recent-comments:not(.vrtx-additional-content-last):not(.vrtx-distach-bottom) {
    margin-bottom: -31px !important;
    border-bottom: none !important;
    box-shadow: 4px 0px @color-neutral--light;
  }
  .vrtx-distatch-bottom {
    margin-bottom: 31px !important;
  }

  .vrtx-event-component li,
  .vrtx-feed li {
    background: none repeat scroll 0 0 transparent !important;
    border-bottom: 1px solid silver;
    list-style-type: none;
    width: 100%;
    padding: 0.5em 0 1em !important;
  }
  .vrtx-feed li:last-child {
    border-bottom: none;
  }
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: none;
}

#main .vrtx-back a::before {
  background: url("../images/icon-chevron-back-blue.svg") no-repeat 0 0.2ex;
  background-size: auto 100%;
  content: " ";
}

.ui-accordion.ui-widget {
  .ui-accordion-header {
    &:first-child {
      border-top: 0;
    }
    color: @color-link;
    border-bottom: 0;
    text-indent: -12px;
    padding-left: 47px;
    .ui-icon {
      background: transparent url("../images/accordion-collapsed.png") no-repeat
        center left;
      margin-right: 10px;
    }
    &.ui-accordion-header-active .ui-icon {
      background-image: url("../images/accordion-expanded.png");
    }
  }
}

hr,
#vrtx-additional-content hr {
  background: url(../images/zigzag.png) repeat-x scroll -3px 100% transparent;
  height: 3px;
  border-bottom: none;
}

div.list-item {
  //background: url(../images/green-square.png) no-repeat scroll 0 2px transparent;
  background-color: @color-secondary;
  color: #fff !important;
  float: left;
  margin-left: 0 !important;
  margin-right: 18px;
  padding-bottom: 0;
  padding-right: 7px;
  padding-left: 7px !important;
}

div.list p,
div.list span,
div.list ul,
div.list table {
  margin-left: 44px;
}
div.list li p,
div.list p strong,
div.list p b,
div.list p h3,
div.list p h2 {
  margin-left: 0;
}
div.list strong,
div.list b,
div.list h3,
div.list h2 {
  margin-left: 44px;
}
div.checklist-item-big {
  background: url(../images/checkbox.svg) no-repeat scroll 0 0 transparent;
  padding-bottom: 0;
  padding-left: 62px !important;
  padding-right: 7px;
}
div.checklist-item-big h2,
div.list h2 {
  font-family: "DIN-OT-bold";
  font-weight: 400;
  font-size: 24px;
  margin-bottom: -2px;
  line-height: 34px;
}

.uio-main ul > li:before,
.grid-container ul > li:before {
  content: "\25CF";
}

table {
  th {
    background-color: @color-neutral--light;
    color: @color-dark;
  }
  &.vertical tbody th[scope="row"] {
    white-space: pre-wrap;
  }
}

#bottomnav {
  display: none !important;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }

  #footer-wrapper {
    background: url(../images/footer-so.png);
    border-top: 4px solid @color-lines;
    line-height: 1.5;
    *,
    a {
      font-size: 16px;
      font-size: 1.6rem;
      color: @color-link;
    }
    .menu-label {
      font-weight: bold;
      color: @color-neutral--dark !important;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      display: inline-block;
      width: 21px;
      height: 22px;
      background: url("../images/dropdown-black.png") no-repeat center right;
    }
    #footers {
      .logo-unit {
        margin-top: 14px;
        margin-left: 30px;
      }
      .logo-hkdir {
        margin-top: -25px;
        margin-left: -30px;
      }
      .logo-kd,
      .logo-hkdir,
      .logo-unit {
        position: absolute;
        padding: 3px 0 0 3px;
        border: 3px solid transparent;
        display: block;
        border-radius: 5px;
        &:hover,
        &:focus {
          color: @color-link !important;
          text-decoration: none;
          border-color: @color-link;
        }
      }
      .col-3-5 {
        width: 68%;
        .col-1-3
          .content
          a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link) {
          &:hover,
          &:focus {
            background-color: transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }
        }
        .col-1-3 .unit {
          margin-top: 14px;
          margin-left: 30px;
        }
      }
      .col-2-5 {
        width: 32%;
        .content {
          margin-left: 40px;
        }
        &.responsible-login p {
          margin: 10px 0;
        }
      }
    }
  }
}

/*****************************
grade-calculator
******************************/

#main .grade-calculator {
  border: 1px solid @color-neutral--light;
  box-shadow: 4px 4px 0 0 @color-neutral--light;
  margin: 1rem 0 3rem;
  line-height: 1.5;
  @media (min-width: 701px) {
    padding: 4rem;
  }
  @media (max-width: 700px) {
    padding: 3rem 5vw;
  }
  p {
    margin: 0;
  }
  .heading {
    display: block;
    margin-bottom: 3rem;
    font-size: 2rem;
    color: @color-dark;
  }
  .intro {
    margin: -2.5rem 0 3rem;
  }
  // input area
  .input-area {
    margin-bottom: 4rem;
  }
  .input-item {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-column-gap: 1.5rem; // fallback
    column-gap: 1.5rem;
    margin-bottom: 3rem;
    label {
      font-weight: bold;
      color: @color-dark;
    }
    .description {
      font-size: 1.4rem;
    }
    input {
      grid-row-start: 1;
      background: @color-neutral--background;
      border-bottom-color: @color-neutral--dark;
      padding: 0.5rem;
      margin: 0;
      height: auto;
      min-height: 44px;
      text-align: center;
      @media (min-width: 701px) {
        font-size: 2rem;
        width: 7rem;
      }
      @media (max-width: 700px) {
        width: 5rem;
      }
      &::placeholder {
        color: @color-neutral;
      }
      &:focus::placeholder {
        color: transparent;
      }
      &[aria-invalid="true"] {
        border: 2px dashed @color-error;
      }
    }
  }
  // error
  .error {
    font-weight: bold;
    color: @color-error;
    &::before {
      content: "!";
      background: @color-error;
      color: white;
      padding: 0 0.5rem;
      margin-right: 0.5rem;
    }
  }
  // output-area
  .output-area {
    border-top: 1px solid @color-neutral--light;
    position: relative;
    // arrow
    &::before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100' width='100'%3E%3Cpolygon points='20,20 80,20 50,50' fill='%23038136' stroke='white' stroke-width='7'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      width: 5rem;
      height: 5rem;
      position: absolute;
      top: -1.3rem;
      margin-left: 1rem;
    }
    @media (min-width: 701px) {
      padding: 3rem 4rem 0;
      margin: 0 -4rem; // bleed (subtract container padding)
    }
    @media (max-width: 700px) {
      padding: 3rem 5vw 0;
      margin: 0 -5vw; // bleed (subtract container padding)
    }
  }
  .output-item {
    strong {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: normal;
      &::after {
        content: ":";
      }
    }
    span {
      font-size: 3rem;
      font-weight: bold;
    }
    .result-warning {
      margin-top: 0.75rem;
    }
  }
}

/****************************
responsive
*****************************/

@media all {
  .page-break {
    display: none;
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .vrtx-frontpage-full-width.total-main #main .grid-color-transparent .row {
    flex-flow: column;
  }
  .vrtx-frontpage-box.grey-box.no-vertical-line {
    margin-right: 0px;
    margin-left: 0px;
  }

  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      //height: 177px;
      #head {
        //height: 130px;
        #header {
          .top-menu {
            right: 14px;
            top: 0px;
            a.teksthint {
              display: none;
            }
          }
          #logo {
            top: 40px;
            left: 20px;
            max-width: 40%;
          }
          .faq {
            top: 125px;
            right: 14px;
          }
        }
      }
    }
    #menu-wrapper .menu {
      background: @color-dark none repeat scroll 0 0;
      li.active,
      li.active a.toggle-search {
        background: @color-neutral none repeat scroll 0 0;
      }
      li a.toggle-search {
        background: @color-dark;
      }
    }
    .menu-search {
      :focus {
        box-shadow: none; // override global rule
      }
      input[type="text"] {
        border-right-width: 5em !important;
        padding-right: 0px;
      }
      button {
        background-color: @color-neutral--light;
        color: @color-dark;
        .transition(all, 0.5s, ease-in);
        &:focus,
        &:hover {
          background-color: @color-neutral--dark;
          color: @color-light;
        }
      }
    }
    #main {
      .image-left {
        max-width: 100% !important;
      }
    }
    #nav-offcanvas li ul > li::before {
      font-size: 0.6em;
      top: 0.5em !important;
    }

    #main .vrtx-frontpage-box.shadowbox,
    .shadowbox &.tidsfrist {
      h2 {
        padding-top: 15px !important;
      }
      .vrtx-box-content .vrtx-feed {
        padding-left: 15px;
      }
    }
    .shadowbox {
      border: none;
      box-shadow: none;
      padding: 0;
    }
    #vrtx-additional-content
      .vrtx-frontpage-box.vrtx-distach-bottom:not(.grey-box):not(.grey-box-light):not(.uio-info-message):not(#footer-wrapper),
    #vrtx-frontpage
      #main
      .grid-container:not(.row-last-colored)
      + .grid-container:not(.row-first-colored) {
      border: none;
    }

    #vrtx-additional-content .vrtx-frontpage-box,
    .vrtx-frontpage-box.so-box,
    .vrtx-frontpage-box.white-box {
      border: none;
      box-shadow: none;
    }
    .vrtx-frontpage-box.responsive-pos-97,
    .vrtx-frontpage-box.responsive-pos-98,
    .vrtx-frontpage-box.responsive-pos-99 {
      .center-image {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        img {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    // main / big search
    #main #total-main .vrtx-big-search {
      :focus {
        box-shadow: none; // override global rule
      }
      input {
        background: none; // prevent iOS bg
      }
    }
    #footer-wrapper {
      #footers {
        .col-3-5 {
          width: 100%;
          .col-1-3 {
            .unit,
            .kd {
              min-height: 60px;
              margin-left: 0;
            }
            .logo-kd,
            .logo-unit {
              position: relative;
              margin-left: 0;
            }
          }
        }
        .col-2-5 {
          width: 100%;
          .content {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
  #main {
    margin-top: 130px !important;
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
//@color-primary          : #408e60;
@color-secondary: #038136; // #367a52;
@color-contrast: #f0f0f0; //#ffce00;
@color-link: #005980;//#00608a #0079ae;
@colored-box: #f5f7f8;
// Error colors
@color-error: #c00;

// Neutral colors
@color-neutral--dark: #444444;
@color-neutral: #777777;
@color-neutral--background: #f6f6f6;
@color-neutral--light: #cecece;
@color-lines: #eaeaea;
@color-grey-box: #ecf3f1;

// Button colors
@color-button--light: #017cba;
@color-button--dark: #016ea4;
@color-button-hover--light: #06617f; //:#0195C7;
@color-button-hover--dark: #024a60; //:#0186B6;

// Supplementary colors
@color-light: #ffffff;
@color-dark: #222222;

// Spacing
@unit: 5px;
@spacing--xlarge: 100px;
@spacing--large: 40px;
@spacing: 20px;
@spacing--small: 10px;

/*------------------------------------*\
# FONT
\*------------------------------------*/

@sans-serif: "Roboto", sans-serif;
@serif: "Roboto Slab", serif;
